<template>
  <v-card
    v-resize="onResize"
    color="neutral"
    class="rounded-xl"
  >
    <v-list
      color="neutral"
      class="settings-mobile-menu rounded rounded-xl"
    >
      <SettingsList />
    </v-list>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import SettingsList from '@/pages/settings/components/SettingsList.vue'

export default {
  name: 'Settings',
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  components: {
    SettingsList
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {}
    }
  },
  computed: {
    ...mapState([
      'brandName'
    ])
  },
  methods: {
    onResize () {
      if (!this.$vuetify.breakpoint.xs) {
        this.$router.push({ name: 'settings' })
      }
    }
  }
}
</script>
