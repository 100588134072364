<template>
  <span class="settings-menu">
    <v-list
      color="neutral"
      class="py-2 rounded-xl"
      rounded
    >
      <template v-for="setting in listItems.pages">
        <v-list-item
          :id="setting.page"
          :key="setting.title"
          :to="{ name: setting.page }"
          exact
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t(setting.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </span>
</template>

<script>
import { mapState } from 'vuex'

import settingsRoutes from '@/config/settingsRoutes.js'
import settingsRoutesNoBankId from '@/config/settingsRoutesNoBankId.js'
export default {
  data () {
    return {
      settingsRoutes,
      settingsRoutesNoBankId
    }
  },
  computed: {
    ...mapState(['nin']),
    listItems: function () {
      if (this.nin) {
        return this.settingsRoutes
      } else {
        return this.settingsRoutesNoBankId
      }
    }
  }
}
</script>
