var settingsRoutes = {
  pages: [
    {
      title: 'Pages.Settings.account_settings',
      page: 'settings'
    },
    {
      title: 'Pages.Settings.privacy',
      page: 'privacy'
    }
  ]
}

export default settingsRoutes
